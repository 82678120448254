import ContentGuard, { ContentGuardCheck } from "../components/contentGuard"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
	LocationMarkerIcon,
	MailIcon,
	PhoneIcon,
} from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout/layout"
import NumberFormat from "react-number-format"
import Resource from "../components/resources/resource"
import Seo from "../components/seo"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import StaticContent from "../components/storyblok/staticContent"

const EventItem = ({
	data: { eventItem, allFreeResources, allEventAssets, allEventProducts },
}) => {
	const {
		isAuthenticated,
		isLoading,
		loginWithRedirect,
		user,
		getIdTokenClaims,
	} = useAuth0()

	const eventProducts = allEventProducts.nodes.filter(
		product => product.eventId === eventItem.eventId
	)
	const eventItemWithProducts = {
		...eventItem,
		products: eventProducts,
	}

	const [product, setProduct] = useState(eventItemWithProducts.products[0])
	const [soldOut, setSoldOut] = useState(false)

	const checkTickets = async () => {
		const tokenClaims = await getIdTokenClaims()

		try {
			const response = await axios.post(
				`${process.env.GATSBY_API_BASE_URL}/order/EventTicketInfo`,
				{ eventId: eventItem.eventId },
				{
					headers: {
						Authorization: `Bearer ${tokenClaims?.__raw}`,
					},
				}
			)

			if (response.status) {
				const { data } = response

				setSoldOut(data.isSoldOut)
			}
		} catch {}
	}

	useEffect(async () => {
		await checkTickets()
	}, [])

	if (isLoading) {
		return null
	}

	const onLoginClicked = () => {
		loginWithRedirect({
			appState: { returnTo: window.location.pathname },
		})
	}

	return (
		<Layout>
			<Seo title={`IPWEA Event - ${eventItem.name}`} />
			{/* <Thumbnail eventItem={eventItem} /> */}
			<Header
				eventItem={eventItemWithProducts}
				product={product}
				soldOut={soldOut}
				setProduct={setProduct}
				products={eventProducts}
			/>
			<div className="bg-gray-200 h-full w-full min-h-screen">
				<EventDetails
					eventItem={eventItemWithProducts}
					allFreeResources={allFreeResources}
					allEventAssets={allEventAssets}
					isAuthenticated={isAuthenticated}
					loginClicked={onLoginClicked}
					user={user}
					product={product}
				/>
			</div>
		</Layout>
	)
}

const Thumbnail = ({ eventItem: { name, thumbnailImages } }) => {
	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages.childImageSharp)
	return (
		(image && (
			<div className="aspect-content aspect-w-16 aspect-h-9 w-full">
				<GatsbyImage
					image={image}
					alt={name}
					className="aspect-content h-48 w-full"
				/>
			</div>
		)) ||
		null
	)
}

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const Header = ({ eventItem, product, setProduct, soldOut, products }) => {
	const onPurchase = item => {
		const productId = item?.productId || product?.productId || item || product
		const currentProduct = products.find(p => p.productId === productId)
		if (currentProduct?.externalEmailRegistrationLink) {
			window.location.href = currentProduct.externalEmailRegistrationLink
		} else {
			navigate(`/create-order?productId=${productId}`, {
				replace: true,
			})
		}
	}

	const handleProductChange = e => {
		setProduct(e.target.value)
	}

	const FormatDateTime = time =>
		new Intl.DateTimeFormat("en-AU", {
			timeZone: "Australia/NSW",
			year: "numeric",
			month: "numeric",
			day: "2-digit",
		}).format(new Date(time))

	const valid = product => {
		let validString = ""
		if (product.validFrom) {
			validString += ` - Valid from ${FormatDateTime(
				new Date(product.validFrom)
			)}`
		}
		if (product.validTo) {
			validString += ` - Valid to ${FormatDateTime(new Date(product.validTo))}`
		}
		return validString
	}

	return (
		<div className="grid md:grid-cols-2 grid-cols-1 pt-5 ">
			<div className="m-5 md:ml-12">
				<h1 className="text-4xl py-4 font-bold text-neutral">
					{eventItem.name}
				</h1>
				{eventItem.isOnDemand ? (
					<span>On Demand</span>
				) : (
					<span className="">
						<DateTime time={eventItem.from} /> -{" "}
						<DateTime time={eventItem.to} />
					</span>
				)}

				<div className="py-4">
					<strong>{eventItem.eventType}</strong>
				</div>
			</div>
			<div className="text-center inline-block align-middle m-auto w-3/4">
				{soldOut ? (
					<div></div>
				) : (
					<div>
						<label
							htmlFor="products"
							className="block text-sm font-medium text-gray-700"
						>
							Select a Product
						</label>
						<select
							onChange={handleProductChange}
							id="products"
							name="products"
							className="w-full mt-1 mb-5 block pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-neutral focus:border-neutral sm:text-sm rounded-md text-center"
						>
							{eventItem.products.map(p => (
								<option key={p.productId} value={p.productId}>
									{p.productName}
									{valid(p)}
								</option>
							))}
						</select>
					</div>
				)}

				<button
					onClick={() => onPurchase(product)}
					disabled={soldOut}
					className={classNames(
						"btn btn-primary w-full h-16 font-semibold text-xl capitalize"
					)}
				>
					{soldOut ? "Sold Out" : "Register Here"}
				</button>
			</div>
		</div>
	)
}

const DateTime = ({ time }) => {
	const longFormat = new Intl.DateTimeFormat("en-AU", {
		timeZone: "Australia/NSW",
		year: "numeric",
		month: "long",
		day: "2-digit",
		weekday: "long",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	})

	return longFormat.format(new Date(time))
}

const EventAbstract = ({ eventItem }) => {
	return (
		<div className="mb-2">
			<p
				className="h-full max-w-prose crmContent"
				dangerouslySetInnerHTML={{ __html: eventItem.abstract }}
			></p>
		</div>
	)
}

const EventDetails = ({
	eventItem,
	allFreeResources,
	allEventAssets,
	isAuthenticated,
	user,
	loginClicked,
	allEventProducts,
	product,
}) => {
	if (ContentGuardCheck(eventItem.visibility, isAuthenticated, user)) {
		return (
			<div className="crmContent">
				<h1 className="pt-5 md:ml-12 m-2 text-2xl semi-bold text-neutral">
					Event Abstract
				</h1>
				<div className="grid grid-cols-1 md:grid-cols-2 ">
					<div className="md:ml-12 m-2">
						{eventItem.abstract && <EventAbstract eventItem={eventItem} />}
						<ContentGuard
							loginCallBack={loginClicked}
							contentVisibility={eventItem.visibility}
						/>
					</div>
				</div>
			</div>
		)
	}
	const isTrainingItem = item =>
		item.eventType === "PD Workshops" || item.eventType === "Online Learning"

	return (
		<div className=" ">
			<h1 className="pt-5 md:ml-12 m-2 text-2xl semi-bold text-neutral">
				Event Description
			</h1>

			<div className="grid grid-cols-1 md:grid-cols-5 ">
				<div className="md:ml-12 m-2 col-span-3 overflow-x-scroll no-scrollbar ">
					{eventItem.abstract && <EventAbstract eventItem={eventItem} />}
					<div>
						<div
							className="crmContent"
							id="dynamics_content"
							dangerouslySetInnerHTML={{ __html: eventItem.content }}
						/>
					</div>
					{eventItem.additionalEventInformation && (
						<div>
							<h3 className="pt-5 text-2xl semi-bold text-neutral">
								Additional Information
							</h3>

							<div
								className="crmContent"
								id="dynamics_additional_content"
								dangerouslySetInnerHTML={{
									__html: eventItem.additionalEventInformation,
								}}
							/>
						</div>
					)}
				</div>
				<div className="h-full align-middle p-2 md:ml-20 w-full md:w-3/4 col-span-2">
					<PriceCard eventItem={eventItem} product={product} />
					<LocationCard eventItem={eventItem} />
					<RelatedAssets
						eventItem={eventItem}
						allFreeResources={allFreeResources}
						allEventAssets={allEventAssets}
					/>
					<ContactInformation isEvent={!isTrainingItem(eventItem)} />
				</div>
			</div>
		</div>
	)
}

const PriceCard = ({ eventItem, product }) => {
	let currentProduct = eventItem.products.find(p => p.productId === product)

	if (currentProduct === undefined) currentProduct = eventItem.products[0]
	return (
		<div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 pb-6">
			<div className="bg-white py-6 px-4 sm:p-6 shadow sm:overflow-hidden w-full">
				<div className="grid grid-cols-2 justify-items-center">
					<div>
						<dt className="text-sm font-medium">Standard Price:</dt>
						<dd className="mt-1 text-lg">
							<NumberFormat
								value={currentProduct?.standardPrice || 0}
								displayType={"text"}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
							/>
						</dd>
					</div>
					<div>
						<dt className="text-sm font-medium">Member Price:</dt>
						<dd className="mt-1 text-lg">
							<NumberFormat
								value={currentProduct?.memberPrice || 0}
								displayType={"text"}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
							/>
						</dd>
					</div>
				</div>
				{currentProduct?.includesAdminFee ? (
					<p className="text-center text-xs pt-4">
						Standard Price includes $100 administration fee
					</p>
				) : null}
				<p className="text-center text-xs pt-4">
					Prices shown are excluding GST
				</p>
			</div>
		</div>
	)
}

const LocationCard = ({ eventItem }) => {
	const isOnlineEvent = eventItem.eventType == "Online Learning"
	const location = isOnlineEvent ? "Online" : eventItem.location

	return (
		<div className="sm:px-6 lg:px-0 lg:col-span-9 pb-6">
			<div className="bg-white py-6 px-4 sm:p-6 shadow sm:overflow-hidden w-full">
				<div className="grid grid-cols-1 pl-0 justify-items-center">
					<h3 className="font-bold">
						{!isOnlineEvent
							? `${location}, ${eventItem?.addressState}`
							: location}
					</h3>
					{!isOnlineEvent && (
						<div className="pt-2">
							<GoogleMapsLink eventItem={eventItem} />
						</div>
					)}
				</div>
			</div>
			<div className="w-full">
				<Thumbnail eventItem={eventItem} />
			</div>
		</div>
	)
}

const GoogleMapsLink = ({ eventItem }) => {
	const query = [
		eventItem.location,
		eventItem.addressStreet,
		eventItem.addressCity,
		eventItem.addressState,
		"au",
	]
		.filter(x => !!x)
		.join(",")
	return (
		<a
			href={`http://maps.google.com/?q=${encodeURI(query)}`}
			target="_blank"
			className="flex text-blue-500 "
		>
			<LocationMarkerIcon className="h-5 w-5" />
			<span className="pl-2">See Map</span>
		</a>
	)
}

const RelatedAssets = ({ eventItem, allFreeResources, allEventAssets }) => {
	const relatedAssetsIds = allEventAssets.nodes.filter(
		asset => asset.eventId === eventItem.eventId
	)

	const relatedAssets = allFreeResources.nodes.filter(asset =>
		relatedAssetsIds.some(
			relatedAssetId => asset.file === relatedAssetId.assetId
		)
	)
	console.log(relatedAssets, 'relatedAssets')

	return (
		<div className="sm:px-6 lg:px-0 lg:col-span-9 pb-6 text-center">
			<div className="bg-white py-6 px-4 sm:p-6 shadow sm:overflow-hidden w-auto">
				<div className="grid grid-cols-1 pl-0  ">
					<h3 className="font-bold">Related Assets</h3>
					<div className="pt-2 grid grid-cols-2 gap-2">
						{relatedAssets.map((r, idx) => (
							<Resource 
								resource={r} 
								key={idx} 
								useCardStyle={true} 
								thumbnailUrl={r.thumbnail && r.thumbnail.length > 0  && r.thumbnail[0]?.thumbnailUrl} 
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

const ContactInformation = ({ isEvent }) => {
	const trainingStrings = ["trainingmobile", "trainingemail", "trainingname"]
	const eventStrings = ["eventmobile", "eventemail", "eventname"]

	const eventInfo = isEvent ? eventStrings : trainingStrings

	return (
		<section className="py-4">
			<h2 className="text-2xl font-bold text-neutral sm:text-3xl">
				Contact Information
			</h2>
			<div className="mt-2">
				<div className="text-lg text-gray-500">
					<StaticContent id={eventInfo[2]} />
				</div>
			</div>
			<div className="mt-2">
				{[
					{
						icon: PhoneIcon,
						contact: <StaticContent id={eventInfo[0]} />,
					},
					{
						icon: MailIcon,
						contact: <StaticContent id={eventInfo[1]} />,
					},
				].map((c, i) => {
					return (
						<div key={i} className="flex">
							<div className="flex-shrink-0">
								<c.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
							</div>
							<div className="ml-3 text-base text-gray-500">
								<div>{c.contact}</div>
							</div>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default EventItem

export const pageQuery = graphql`
	query EventItemQuery($itemId: String!) {
		eventItem(eventId: { eq: $itemId }) {
			eventId
			abstract
			permalink
			eventType
			name
			location
			addressStreet
			addressCity
			addressState
			from
			to
			isOnDemand
			content
			additionalEventInformation
			visibility
			thumbnailImages {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
		allFreeResources {
			nodes {
				name
				file
				fileName
				contentVisibility
				permalink
				thumbnail {
					thumbnailUrl
					url
				}
			}
		}
		allEventAssets {
			nodes {
				assetId
				eventId
			}
		}
		allEventProducts {
			nodes {
				memberPrice
				productId
				eventId
				standardPrice
				validFrom
				validTo
				productName
				includesAdminFee
				externalEmailRegistrationLink
			}
		}
	}
`
